import _objectSpread from "/home/jenkins/workspace/diTuiRen_web_cs/01Code/platform/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
import qs from 'qs'; // create an axios instance

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  },
  transformRequest: [function (data) {
    if (getToken()) {
      data = _objectSpread(_objectSpread({}, data), {}, {
        token: getToken()
      });
    }

    data = qs.stringify(data);
    return data;
  }],
  timeout: 50000 // request timeout

}); // request interceptor

service.interceptors.request.use(function (config) {
  // do something before request is sent
  // if (store.getters.token) {
  // let each request carry token
  // ['X-Token'] is a custom headers key
  // please modify it according to the actual situation
  // config.headers['token'] = getToken()
  // }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data; // if the custom code is not 20000, it is judged as an error.

  if (!res.success) {
    Message({
      message: res.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    }); // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

    if (res.code == 9001) {
      // to re-login
      // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //   confirmButtonText: 'Re-Login',
      //   cancelButtonText: 'Cancel',
      //   type: 'warning'
      // }).then(() => {
      //   store.dispatch('user/resetToken').then(() => {
      //     location.reload()
      //   })
      // })
      MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定退出', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消'
      }).then(function () {
        store.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      });
    }

    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + error); // for debug

  Message({
    message: error.message,
    type: 'error',
    duration: 2 * 1000
  });
  return Promise.reject(error);
});
export default service;