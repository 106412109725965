import _typeof from "/home/jenkins/workspace/diTuiRen_web_cs/01Code/platform/node_modules/@babel/runtime-corejs2/helpers/esm/typeof";
import Cookies from 'js-cookie';
var TokenKey = 'vue_admin_dituiren_token';
var UserInfoKey = 'vue_dituiren_platform_userinfo';
export function getToken() {
  return sessionStorage.getItem(TokenKey);
}
export function setToken(token) {
  return sessionStorage.setItem(TokenKey, token);
}
export function removeToken() {
  return sessionStorage.removeItem(TokenKey);
} // 处理用户信息

export function getUserInfo() {
  var target = Cookies.get(UserInfoKey);

  if (target != undefined) {
    return JSON.parse(target);
  } else {
    return {};
  }
}
export function setUserInfo(userinfo) {
  var target = userinfo;

  if (_typeof(userinfo) === 'object') {
    target = JSON.stringify(userinfo);
  }

  return Cookies.set(UserInfoKey, target);
}
export function removeUserInfo() {
  return Cookies.remove(UserInfoKey);
}
export function timeChose(time) {
  if (time) {
    var oDate = new Date(time * 1);
    var oYear = oDate.getFullYear();
    var oMonth = oDate.getMonth() + 1;
    var oDay = oDate.getDate();
    var oHour = oDate.getHours();
    var oMin = oDate.getMinutes();
    var oSen = oDate.getSeconds();
    var oTime = oYear + '-' + getBz(oMonth) + '-' + getBz(oDay) + ' ' + getBz(oHour) + ':' + getBz(oMin) + ':' + getBz(oSen); // 拼接时间

    return oTime;
  } else {
    return '';
  }
} // 补0

export function getBz(num) {
  if (parseInt(num) < 10) {
    num = '0' + num;
  }

  return num;
}