import request from '@/utils/request'; //人员列表

export function accountList(data) {
  return request({
    url: '/admin/user/find',
    method: 'post',
    data: data
  });
} //新增人员

export function accountSave(data) {
  return request({
    url: '/admin/user/save',
    method: 'post',
    data: data
  });
} //人员详情

export function accountDetails(data) {
  return request({
    url: '/admin/user/get',
    method: 'post',
    data: data
  });
} //人员启用

export function accountStart(data) {
  return request({
    url: '/admin/user/start',
    method: 'post',
    data: data
  });
} //人员停用

export function accountStop(data) {
  return request({
    url: '/admin/user/stop',
    method: 'post',
    data: data
  });
} //编辑人员

export function accountUpdate(data) {
  return request({
    url: '/admin/user/update',
    method: 'post',
    data: data
  });
} //删除人员

export function accountDelete(data) {
  return request({
    url: '/admin/user/delete',
    method: 'post',
    data: data
  });
} //角色列表

export function roleAllList(data) {
  return request({
    url: '/admin/role/list',
    method: 'post',
    data: data
  });
} //角色列表（分页）

export function roleList(data) {
  return request({
    url: '/admin/role/find',
    method: 'post',
    data: data
  });
} //角色是否启动
// export function roleStart(data) {
//   return request({
//     url: '/admin/role/updateStatus',
//     method: 'post',
//     data
//   })
// }
//角色删除

export function roleDelete(data) {
  return request({
    url: '/admin/role/delete',
    method: 'post',
    data: data
  });
} //角色 菜单列表

export function roleMenuList(data) {
  return request({
    url: '/admin/role/menu/list',
    method: 'post',
    data: data
  });
} //角色 操作权限

export function roleOperation(data) {
  return request({
    url: '/admin/role/auth/list',
    method: 'post',
    data: data
  });
} //新增角色，编辑角色保存

export function roleSave(data) {
  return request({
    url: '/admin/role/save',
    method: 'post',
    data: data
  });
} //角色详情

export function roleDetail(data) {
  return request({
    url: '/admin/role/get',
    method: 'post',
    data: data
  });
} //当前用户 菜单列表 权限

export function getRoutes(data) {
  return request({
    url: "/admin/menu/get",
    method: "post",
    data: data
  });
} // 海报管理============================================
//海报列表数据

export function posterFindPage(data) {
  return request({
    url: "/admin/backdropImg/findPage",
    method: "post",
    data: data
  });
} //海报删除

export function posterUpdateStatus(data) {
  return request({
    url: "/admin/backdropImg/updateStatus",
    method: "post",
    data: data
  });
} // 添加上传海报

export function backdropImgSave(data) {
  return request({
    url: "/admin/backdropImg/save",
    method: "post",
    data: data
  });
}