var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        _c(
          "el-dropdown",
          { staticClass: "avatar-container", attrs: { trigger: "click" } },
          [
            _c("div", { staticClass: "avatar-wrapper" }, [
              _c("img", {
                staticClass: "user-avatar",
                attrs: { src: _vm.advertPath }
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { margin: "0 10px", cursor: "pointer" } },
                [_vm._v(_vm._s(_vm.userName))]
              ),
              _vm._v(" "),
              _c("i", { staticClass: "el-icon-caret-bottom" })
            ]),
            _vm._v(" "),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "user-dropdown",
                attrs: { slot: "dropdown" },
                slot: "dropdown"
              },
              [
                _c("el-dropdown-item", [
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block", padding: "0 20px" },
                      on: {
                        click: function($event) {
                          return _vm.editPass()
                        }
                      }
                    },
                    [_vm._v("修改密码")]
                  )
                ]),
                _vm._v(" "),
                _c("el-dropdown-item", { attrs: { divided: "" } }, [
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block", padding: "0 20px" },
                      on: { click: _vm.logout }
                    },
                    [_vm._v("退出登录")]
                  )
                ])
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.dialogTitle,
              visible: _vm.dialogFormVisible,
              "custom-class": "new-dialog",
              "append-to-body": "",
              width: "700px",
              "close-on-click-modal": false
            },
            on: {
              "update:visible": function($event) {
                _vm.dialogFormVisible = $event
              }
            }
          },
          [
            _c(
              "el-form",
              {
                ref: "dataForm",
                staticStyle: { width: "500px", "margin-left": "50px" },
                attrs: {
                  model: _vm.temp,
                  rules: _vm.rules,
                  "label-position": "right",
                  "label-width": "100px"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "原密码", prop: "oldPassword" } },
                  [
                    _c("el-input", {
                      attrs: { type: "password", autocomplete: "off" },
                      model: {
                        value: _vm.temp.oldPassword,
                        callback: function($$v) {
                          _vm.$set(_vm.temp, "oldPassword", $$v)
                        },
                        expression: "temp.oldPassword"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "新密码", prop: "newPassword" } },
                  [
                    _c("el-input", {
                      attrs: { type: "password", autocomplete: "off" },
                      model: {
                        value: _vm.temp.newPassword,
                        callback: function($$v) {
                          _vm.$set(_vm.temp, "newPassword", $$v)
                        },
                        expression: "temp.newPassword"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "确认密码", prop: "checkPass" } },
                  [
                    _c("el-input", {
                      attrs: { type: "password", autocomplete: "off" },
                      model: {
                        value: _vm.temp.checkPass,
                        callback: function($$v) {
                          _vm.$set(_vm.temp, "checkPass", $$v)
                        },
                        expression: "temp.checkPass"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.dialogFormVisible = false
                      }
                    }
                  },
                  [_vm._v("\n          取消\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.createData } },
                  [_vm._v("\n          保存\n        ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }