import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/admin/login',
    method: 'post',
    data: data
  });
}
export function getInfo(token) {
  return request({
    url: '/user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}
export function logout() {
  return request({
    url: '/user/logout',
    method: 'post'
  });
} //修改当前员工密码

export function passwordUpdate(data) {
  return request({
    url: '/admin/password/update',
    method: 'post',
    data: data
  });
}