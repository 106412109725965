import "core-js/modules/es6.function.name";
import { login as _login, logout, getInfo as _getInfo } from "@/api/user";
import { getToken, setToken, removeToken, getUserInfo, setUserInfo, removeUserInfo } from "@/utils/auth";
import { resetRouter } from "@/router";
var state = {
  token: getToken(),
  name: "",
  avatar: "",
  userInfo: getUserInfo() || {}
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_USERINFO: function SET_USERINFO(state, userInfo) {
    state.userInfo = userInfo;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
        password = userInfo.password;
    return new Promise(function (resolve, reject) {
      _login({
        userName: username.trim(),
        password: password
      }).then(function (response) {
        var data = response.data;
        commit("SET_TOKEN", data.token);
        setToken(data.token);
        commit("SET_USERINFO", data);
        sessionStorage.setItem('userInfo', data);
        setUserInfo(data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      _getInfo(state.token).then(function (response) {
        var data = response.data;

        if (!data) {
          reject("Verification failed, please Login again.");
        }

        var name = data.name,
            avatar = data.avatar;
        commit("SET_NAME", name);
        commit("SET_AVATAR", avatar);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
        state = _ref3.state;
    // return new Promise((resolve, reject) => {
    //   logout(state.token)
    //     .then(() => {
    commit("SET_TOKEN", "");
    removeToken();
    commit("SET_USERINFO", {});
    removeUserInfo();
    resetRouter();
    sessionStorage.removeItem('userInfo'); // resolve(); 
    // })
    // .catch(error => {
    //   reject(error);
    // });
    // });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit("SET_TOKEN", "");
      removeToken();
      commit("SET_USERINFO", {});
      removeUserInfo();
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};