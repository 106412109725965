import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* Layout */

import Layout from '@/layout';
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: '/bigData' || '/bigdata',
  component: function component() {
    return import('@/views/bigData');
  },
  hidden: true
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, // {
//   path: '/bigPage',
//   component: () => import('@/views/bigPage'),
//   hidden: true
// },
{
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/datacenter',
  children: [{
    path: '/datacenter',
    name: 'Datacenter',
    component: function component() {
      return import('@/views/datacenter/index');
    },
    meta: {
      title: '数据中心',
      icon: 'datacenter'
    }
  }]
}, {
  path: '/team',
  component: Layout,
  redirect: '/team/teamManage',
  name: 'Team',
  meta: {
    title: '团队管理',
    icon: 'teamManage'
  },
  children: [{
    path: '/team/teamManage',
    name: 'TeamManage',
    component: function component() {
      return import('@/views/team/teamManage/index');
    },
    meta: {
      title: '组织架构'
    }
  }, {
    path: '/team/teamManage/addSalesman/:organizeId/:id',
    name: 'AddSalesman',
    component: function component() {
      return import('@/views/team/teamManage/addSalesman');
    },
    hidden: true,
    meta: {
      title: '新增业务员'
    }
  }, {
    path: '/team/teamManage/ImportFailedList',
    name: 'ImportFailedList',
    component: function component() {
      return import('@/views/team/teamManage/ImportFailedList');
    },
    hidden: true,
    meta: {
      title: '导入失败列表'
    }
  }, {
    path: '/team/teamManage/salesmanDetails/:id',
    name: 'SalesmanDetails',
    component: function component() {
      return import('@/views/team/teamManage/salesmanDetails');
    },
    hidden: true,
    meta: {
      title: '业务员详情',
      activeMenu: '/team/teamManage'
    }
  }, {
    path: '/team/postManage',
    name: 'PostManage',
    component: function component() {
      return import('@/views/team/postManage/index');
    },
    meta: {
      title: '架构及岗位管理'
    }
  }, {
    path: '/team/postManage/addPostManage',
    name: 'AddPostManage',
    component: function component() {
      return import('@/views/team/postManage/addPostManage');
    },
    hidden: true,
    meta: {
      title: '新增架构及岗位管理',
      activeMenu: '/team/postManage'
    }
  }, {
    path: '/team/postManage/postTeamManage',
    name: 'PostTeamManage',
    component: function component() {
      return import('@/views/team/postManage/postTeamManage');
    },
    hidden: true,
    meta: {
      title: '团级管理',
      activeMenu: '/team/postManage'
    }
  }, {
    path: '/team/postManage/editTeamManage',
    name: 'EditTeamManage',
    component: function component() {
      return import('@/views/team/postManage/editTeamManage');
    },
    hidden: true,
    meta: {
      title: '编辑团级管理',
      activeMenu: '/team/postManage'
    }
  }]
}, {
  path: '/customer',
  component: Layout,
  children: [{
    path: '/customer/customerManage',
    name: 'CustomerManage',
    component: function component() {
      return import('@/views/customer/customerManage/index');
    },
    meta: {
      title: '客户管理',
      icon: 'customerManage'
    }
  }, {
    path: '/customer/customerManage/customerDetails',
    name: 'CustomerDetails',
    component: function component() {
      return import('@/views/customer/customerManage/customerDetails');
    },
    hidden: true,
    meta: {
      title: '客户详情',
      activeMenu: '/customer/customerManage'
    }
  }]
}, {
  path: '/product',
  component: Layout,
  redirect: '/product/productManage',
  name: 'Product',
  meta: {
    title: '产品管理',
    icon: 'productManage'
  },
  children: [{
    path: '/product/productManage',
    name: 'ProductManage',
    component: function component() {
      return import('@/views/product/productManage/index');
    },
    meta: {
      title: '产品管理'
    }
  }, {
    path: '/product/productManage/addProduct',
    name: 'AddProduct',
    component: function component() {
      return import('@/views/product/productManage/addProduct');
    },
    hidden: true,
    meta: {
      title: '新增产品',
      activeMenu: '/product/productManage'
    }
  }, {
    path: '/product/productGroup',
    name: 'ProductGroup',
    component: function component() {
      return import('@/views/product/productGroup/index');
    },
    meta: {
      title: '产品分组管理'
    }
  }, {
    path: '/product/productGroup/addProductLarge',
    name: 'AddProductLarge',
    component: function component() {
      return import('@/views/product/productGroup/addProductLarge');
    },
    hidden: true,
    meta: {
      title: '新增产品大类',
      activeMenu: '/product/productGroup'
    }
  }, {
    path: '/product/productGroup/addProductSmall',
    name: 'AddProductSmall',
    component: function component() {
      return import('@/views/product/productGroup/addProductSmall');
    },
    hidden: true,
    meta: {
      title: '新增产品细类',
      activeMenu: '/product/productGroup'
    }
  }]
}, {
  path: '/order',
  component: Layout,
  name: 'Order',
  meta: {
    title: '订单管理',
    icon: 'orderManage'
  },
  children: [{
    path: '/order/orderManage',
    name: 'OrderManage',
    component: function component() {
      return import('@/views/order/orderManage/index');
    },
    meta: {
      title: '订单管理列表'
    }
  }, {
    path: '/order/orderManage/orderManageDetail',
    name: 'OrderManageDetail',
    component: function component() {
      return import('@/views/order/orderManage/orderManageDetail');
    },
    hidden: true,
    meta: {
      title: '订单管理详情',
      activeMenu: '/order/orderManage'
    }
  }, {
    path: '/order/posManage',
    name: 'PosManage',
    component: function component() {
      return import('@/views/order/posManage/index');
    },
    meta: {
      title: 'POS商户管理'
    }
  }, {
    path: '/order/posManage/posMangeDetail',
    name: 'PosMangeDetail',
    component: function component() {
      return import('@/views/order/posManage/posMangeDetail');
    },
    hidden: true,
    meta: {
      title: 'POS商户管理详情',
      activeMenu: '/order/posManage'
    }
  }, {
    path: '/order/posManage/posMangeDetailQiye',
    name: 'PosMangeDetailQiye',
    component: function component() {
      return import('@/views/order/posManage/posMangeDetailQiye');
    },
    hidden: true,
    meta: {
      title: 'POS商户管理详情',
      activeMenu: '/order/posManage'
    }
  }, {
    path: '/order/posManage/kaidianbaodetail',
    name: 'Kaidianbaodetail',
    component: function component() {
      return import('@/views/order/posManage/kaidianbaodetail');
    },
    hidden: true,
    meta: {
      title: 'POS商户管理详情'
    }
  }, {
    path: '/order/posManage/qudaoDetail',
    name: 'QudaoDetail',
    component: function component() {
      return import('@/views/order/posManage/qudaoDetail');
    },
    hidden: true,
    meta: {
      title: 'POS商户管理详情'
    }
  }, {
    path: '/order/posManage/qudaoDetailQiye',
    name: 'QudaoDetailQiye',
    component: function component() {
      return import('@/views/order/posManage/qudaoDetailQiye');
    },
    hidden: true,
    meta: {
      title: 'POS商户管理详情'
    }
  }]
}, {
  path: '/shopCollection',
  component: Layout,
  name: 'ShopCollection',
  meta: {
    title: '商家收款管理',
    icon: 'orderManage'
  },
  children: [{
    path: '/shopCollection/shopApply',
    name: 'ShopApplyIndex',
    component: function component() {
      return import('@/views/shopCollection/shopApply');
    },
    meta: {
      title: '商家管理'
    }
  }, {
    path: '/shopCollection/shopApply/shopApplyDetail/:id',
    name: 'ShopApplyDetail',
    component: function component() {
      return import('@/views/shopCollection/shopApply/shopApplyDetail');
    },
    hidden: true,
    meta: {
      title: '商家服务详情',
      activeMenu: '/shopCollection/shopApply'
    }
  }, {
    path: '/shopCollection/collection',
    name: 'CollectionIndex',
    component: function component() {
      return import('@/views/shopCollection/collection');
    },
    meta: {
      title: '商家收款管理'
    }
  }, {
    path: '/shopCollection/qrCode',
    name: 'QrCode',
    component: function component() {
      return import('@/views/shopCollection/qrCode');
    },
    meta: {
      title: '二维码管理'
    }
  }, {
    path: '/shopCollection/apply',
    name: 'ApplyIndex',
    component: function component() {
      return import('@/views/shopCollection/apply');
    },
    meta: {
      title: '商家提现管理'
    }
  }, {
    path: '/shopCollection/waterSingle/:id',
    name: 'WaterSingle',
    component: function component() {
      return import('@/views/shopCollection/waterSingle');
    },
    hidden: true,
    meta: {
      title: '商家流水',
      activeMenu: '/shopCollection/shopApply'
    }
  }]
}, {
  path: '/task',
  component: Layout,
  redirect: '/task/taskManage',
  name: 'Task',
  meta: {
    title: '任务管理',
    icon: 'form'
  },
  children: [{
    path: '/task/taskManage',
    name: 'TaskManage',
    component: function component() {
      return import('@/views/task/taskManage/index');
    },
    meta: {
      title: '任务管理',
      icon: 'form'
    }
  }, {
    path: '/task/taskManage/addTask',
    name: 'AddTask',
    component: function component() {
      return import('@/views/task/taskManage/addTask');
    },
    hidden: true,
    meta: {
      title: '新增任务管理',
      icon: 'form',
      activeMenu: '/task/taskManage'
    }
  }, {
    path: '/task/taskManage/teskdetal/:id',
    name: 'Teskdetal',
    component: function component() {
      return import('@/views/task/taskManage/teskdetal');
    },
    hidden: true,
    meta: {
      title: '任务管理详情',
      icon: 'form',
      activeMenu: '/task/taskManage'
    }
  }]
}, // {
//   path: '/useropus',
//   component: Layout,
//   children: [
//     {
//       path: '/useropus/index',
//       name: 'useropus',
//       component: () => import('@/views/useropus/index'),
//       meta: { title: '用户作品管理', icon: 'user' }
//     }, {
//       path: '/useropus/opusDetail/:status/:name/:id/:listening',
//       name: 'opusDetail',
//       component: () => import('@/views/useropus/opusDetail'),
//       meta: { title: '合辑详情' },
//       hidden: true
//     }
//   ]
// },
{
  path: '/examine',
  component: Layout,
  redirect: '/examine/index',
  children: [{
    path: 'index',
    name: 'Examine',
    component: function component() {
      return import('@/views/examine');
    },
    meta: {
      title: '审核管理',
      icon: 'examine'
    }
  }, {
    path: 'teamDetail/:id',
    name: 'TeamDetail',
    component: function component() {
      return import('@/views/examine/teamDetail');
    },
    meta: {
      title: '审核详情'
    },
    hidden: true
  }, {
    path: 'clientDetail/:id',
    name: 'ClientDetail',
    component: function component() {
      return import('@/views/examine/clientDetail');
    },
    meta: {
      title: '审核详情'
    },
    hidden: true
  }, {
    path: 'productDetail/:id',
    name: 'ProductDetail',
    component: function component() {
      return import('@/views/examine/productDetail');
    },
    meta: {
      title: '审核详情'
    },
    hidden: true
  }, {
    path: 'taskDetail/:id',
    name: 'TaskDetail',
    component: function component() {
      return import('@/views/examine/taskDetail');
    },
    meta: {
      title: '审核详情'
    },
    hidden: true
  }, {
    path: 'messageDetail/:id',
    name: 'MessageDetail',
    component: function component() {
      return import('@/views/examine/messageDetail');
    },
    meta: {
      title: '审核详情'
    },
    hidden: true
  }, {
    path: 'moneyDetail/:id',
    name: 'MoneyDetail',
    component: function component() {
      return import('@/views/examine/moneyDetail');
    },
    meta: {
      title: '审核详情'
    },
    hidden: true
  }, {
    path: 'pageDetail/:id',
    name: 'PageDetail',
    component: function component() {
      return import('@/views/examine/pageDetail');
    },
    meta: {
      title: '审核详情'
    },
    hidden: true
  }]
}, {
  path: '/message',
  component: Layout,
  children: [{
    path: 'index',
    name: 'Message',
    component: function component() {
      return import('@/views/message');
    },
    meta: {
      title: '消息推送',
      icon: 'message'
    }
  }, {
    path: 'createAdd/:id',
    name: 'CreateAdd',
    component: function component() {
      return import('@/views/message/createAdd');
    },
    meta: {
      title: '消息通知',
      activeMenu: '/message/index'
    },
    hidden: true
  }, {
    path: 'detail/:id',
    name: 'DetailMessage',
    component: function component() {
      return import('@/views/message/detail');
    },
    meta: {
      title: '消息详情',
      activeMenu: '/message/index'
    },
    hidden: true
  }]
}, {
  path: '/money',
  component: Layout,
  name: 'Money',
  meta: {
    title: '佣金管理',
    icon: 'money'
  },
  children: [{
    path: 'index',
    name: 'Money',
    component: function component() {
      return import('@/views/money');
    },
    meta: {
      title: '佣金管理'
    }
  }, {
    path: 'infomage',
    name: 'Infomage',
    component: function component() {
      return import('@/views/money/infomage');
    },
    meta: {
      title: '佣金信息管理'
    }
  }, {
    path: 'edit/:id/:name',
    name: 'MoneyEdit',
    component: function component() {
      return import('@/views/money/edit');
    },
    meta: {
      title: '返佣模板',
      activeMenu: '/money/index'
    },
    hidden: true
  }, {
    path: 'look/:id/:productId',
    name: 'MoneyLook',
    component: function component() {
      return import('@/views/money/look');
    },
    meta: {
      title: '产品分润',
      activeMenu: '/money/index'
    },
    hidden: true
  }, {
    path: 'lookPeople/:startTime/:productId',
    name: 'MoneyLookPeople',
    component: function component() {
      return import('@/views/money/lookPeople');
    },
    meta: {
      title: '产品分润用户详情'
    },
    hidden: true
  }, {
    path: 'lookDetail/:type/:accountId/:startTime/:productId',
    name: 'MoneyLookDetail',
    component: function component() {
      return import('@/views/money/lookDetail');
    },
    meta: {
      title: '产品分润详情'
    },
    hidden: true
  }]
}, {
  path: '/dataSet',
  component: Layout,
  redirect: '/dataSet/posData',
  name: 'DataSet',
  meta: {
    title: '数据配置',
    icon: 'system'
  },
  children: [{
    path: '/dataSet/posData',
    name: 'PosData',
    component: function component() {
      return import('@/views/dataSet/posData/index');
    },
    meta: {
      title: 'pos机数据'
    }
  }, {
    path: '/dataSet/posRule',
    name: 'PosRule',
    component: function component() {
      return import('@/views/dataSet/posRule/index');
    },
    meta: {
      title: '关联pos机制'
    }
  }, {
    path: '/dataSet/posRule/bindRule',
    name: 'BindRule',
    hidden: true,
    component: function component() {
      return import('@/views/dataSet/posRule/bindRule');
    },
    meta: {
      title: '绑定规则',
      activeMenu: '/dataSet/posRule'
    }
  }]
}, {
  path: '/page',
  component: Layout,
  children: [{
    path: 'index',
    name: 'Page',
    component: function component() {
      return import('@/views/page');
    },
    meta: {
      title: '页面管理',
      icon: 'page'
    }
  }]
}, {
  path: '/poster',
  component: Layout,
  children: [{
    path: 'index',
    name: 'Poster',
    component: function component() {
      return import('@/views/poster');
    },
    meta: {
      title: '海报管理',
      icon: 'orderManage'
    }
  }, {
    path: 'poster/addPoster/:id',
    name: 'AddPoster',
    component: function component() {
      return import('@/views/poster/addPoster');
    },
    meta: {
      title: '上传海报',
      activeMenu: '/poster/index'
    },
    hidden: true
  }]
}, {
  path: '/system',
  component: Layout,
  redirect: '/system/role',
  name: 'System',
  meta: {
    title: '系统管理',
    icon: 'system'
  },
  children: [{
    path: 'role',
    name: 'Role',
    component: function component() {
      return import('@/views/system/role');
    },
    meta: {
      title: '角色管理'
    }
  }, {
    path: '/system/role/detailOne/:id',
    name: 'RoleDetail',
    component: function component() {
      return import('@/views/system/role/detailOne');
    },
    meta: {
      title: '角色详情',
      activeMenu: '/system/role'
    },
    hidden: true
  }, {
    path: 'person',
    name: 'Person',
    component: function component() {
      return import('@/views/system/person');
    },
    meta: {
      title: '人员管理'
    }
  }, {
    path: '/system/person/detailOne/:id',
    name: 'PersonnelDetail',
    component: function component() {
      return import('@/views/system/person/detailOne');
    },
    meta: {
      title: '人员详情',
      activeMenu: '/system/person'
    },
    hidden: true
  } // {
  //   path: 'poster',
  //   name: 'Poster',
  //   component: () => import('@/views/system/poster'),
  //   meta: {
  //     title: '海报管理'
  //   }
  // },
  // {
  //   path: '/system/poster/addPoster',
  //   name: 'AddPoster',
  //   component: () => import('@/views/system/poster/addPoster'),
  //   meta: {
  //     title: '上传海报',
  //     activeMenu: '/system/poster'
  //   },
  //   hidden: true
  // },
  ]
}, // 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;