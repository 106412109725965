import "core-js/modules/es6.function.name";
//import { constantRoutes } from '@/router'
import { getRoutes } from '@/api/system';
import { getUserInfo } from "@/utils/auth";
import Vue from 'vue';
import Layout from '@/layout';

function filterRoutes(routers) {
  var routerTree = [];

  for (var i = 0; i < routers.length; i++) {
    var obj = routers[i];
    var newObj = {
      path: obj.path,
      meta: {
        title: obj.name,
        icon: obj.icon
      }
    };

    if (obj.children.length > 0) {
      Vue.set(newObj, 'children', filterRoutes(obj.children));
    }

    routerTree.push(newObj);
  } //console.log('newObj', routerTree)


  return routerTree;
}

var state = {
  routes: []
};
var mutations = {
  SET_PERMISSIONROUTES: function SET_PERMISSIONROUTES(state, routes) {
    // state.addRoutes = routes
    state.routes = routes;
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      getRoutes({
        organizeId: getUserInfo().organizeId == 0 ? '' : getUserInfo().organizeId,
        groupType: 'web'
      }).then(function (res) {
        var accessedRouters = filterRoutes(res.data);
        commit('SET_PERMISSIONROUTES', accessedRouters);
        resolve(accessedRouters);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};